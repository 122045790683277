import React from 'react';
import { graphql } from "gatsby"

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import SpeechlessHeroSection from '../sections/caseStudies/speechless/SpeechlessHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import SpeechlessSection1 from '../sections/caseStudies/speechless/SpeechlessSection1';
import TestimonialSection from '../sections/TestimonialSection';

const Speechless = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudySpeechlessPageACF;
  return (
    <Layout headerClass="white-header">
      <Seo title="Speechless" />
      <div className="speechless-case-study page-wrapper " id="speechless-casestudy-page-wrapper">
        <SpeechlessHeroSection 
            preTitle={parse(mainData.speechlessHeroSectionPretitle)}
            mainTitle={parse(mainData.speechlessHeroSectionTitle)}
            image1x={
              {
                sourceUrl: mainData.speechlessHeroSectionImage1x.sourceUrl,
                altText: mainData.speechlessHeroSectionImage1x.altText,
                width: mainData.speechlessHeroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.speechlessHeroSectionImage2x.sourceUrl,
                altText: mainData.speechlessHeroSectionImage2x.altText,
                width: mainData.speechlessHeroSectionImage2x.width,
              }
            }
        />
        <ChallengesSection
            companyInfoList={mainData.speechlessChallengeSectionCompanyInfoList}
            challengesList={mainData.speechlessChallengeSectionChallangesList}
        />
        <SpeechlessSection1 
            imageslist={mainData.speechlessSection1ImageList}
            image11x={
            {
              sourceUrl: mainData.speechlessSection1Image11x.sourceUrl,
              altText: mainData.speechlessSection1Image11x.altText,
              width: mainData.speechlessSection1Image11x.width,
            }
            }
            image12x={
              {
                sourceUrl: mainData.speechlessSection1Image12x.sourceUrl,
                altText: mainData.speechlessSection1Image12x.altText,
                width: mainData.speechlessSection1Image12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.speechlessSection1Image21x.sourceUrl,
                altText: mainData.speechlessSection1Image21x.altText,
                width: mainData.speechlessSection1Image21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.speechlessSection1Image22x.sourceUrl,
                altText: mainData.speechlessSection1Image22x.altText,
                width: mainData.speechlessSection1Image22x.width,
              }
            }
            mainTitle1={parse(mainData.speechlessSection1Title1)}
            mainDescription1={parse(mainData.speechlessSection1Description1)}
            imageIdeation1x={
            {
              sourceUrl: mainData.speechlessSection1IdeationAndSketchesImage1x.sourceUrl,
              altText: mainData.speechlessSection1IdeationAndSketchesImage1x.altText,
              width: mainData.speechlessSection1IdeationAndSketchesImage1x.width,
            }
            }
            imageIdeation2x={
              {
                sourceUrl: mainData.speechlessSection1IdeationAndSketchesImage2x.sourceUrl,
                altText: mainData.speechlessSection1IdeationAndSketchesImage2x.altText,
                width: mainData.speechlessSection1IdeationAndSketchesImage2x.width,
              }
            }
            imageslist2={mainData.speechlessSection1ImageList2}
            image31x={
            {
              sourceUrl: mainData.speechlessSection1Image31x.sourceUrl,
              altText: mainData.speechlessSection1Image31x.altText,
              width: mainData.speechlessSection1Image31x.width,
            }
            }
            image32x={
              {
                sourceUrl: mainData.speechlessSection1Image32x.sourceUrl,
                altText: mainData.speechlessSection1Image32x.altText,
                width: mainData.speechlessSection1Image32x.width,
              }
            }
            image41x={
            {
              sourceUrl: mainData.speechlessSection1Image41x.sourceUrl,
              altText: mainData.speechlessSection1Image41x.altText,
              width: mainData.speechlessSection1Image41x.width,
            }
            }
            image42x={
              {
                sourceUrl: mainData.speechlessSection1Image42x.sourceUrl,
                altText: mainData.speechlessSection1Image42x.altText,
                width: mainData.speechlessSection1Image42x.width,
              }
            }
            image51x={
            {
              sourceUrl: mainData.speechlessSection1Image51x.sourceUrl,
              altText: mainData.speechlessSection1Image51x.altText,
              width: mainData.speechlessSection1Image51x.width,
            }
            }
            image52x={
              {
                sourceUrl: mainData.speechlessSection1Image52x.sourceUrl,
                altText: mainData.speechlessSection1Image52x.altText,
                width: mainData.speechlessSection1Image52x.width,
              }
            }
            mainTitle2={parse(mainData.speechlessSection1Title2)}
            mainDescription2={parse(mainData.speechlessSection1Description2)}
            imageslist3={mainData.speechlessSection1ImageList3}
            image61x={
            {
              sourceUrl: mainData.speechlessSection1Image61x.sourceUrl,
              altText: mainData.speechlessSection1Image61x.altText,
              width: mainData.speechlessSection1Image61x.width,
            }
            }
            image62x={
              {
                sourceUrl: mainData.speechlessSection1Image62x.sourceUrl,
                altText: mainData.speechlessSection1Image62x.altText,
                width: mainData.speechlessSection1Image62x.width,
              }
            }
            image71x={
            {
              sourceUrl: mainData.speechlessSection1Image71x.sourceUrl,
              altText: mainData.speechlessSection1Image71x.altText,
              width: mainData.speechlessSection1Image71x.width,
            }
            }
            image72x={
              {
                sourceUrl: mainData.speechlessSection1Image72x.sourceUrl,
                altText: mainData.speechlessSection1Image72x.altText,
                width: mainData.speechlessSection1Image72x.width,
              }
            }
            image81x={
            {
              sourceUrl: mainData.speechlessSection1Image81x.sourceUrl,
              altText: mainData.speechlessSection1Image81x.altText,
              width: mainData.speechlessSection1Image81x.width,
            }
            }
            image82x={
              {
                sourceUrl: mainData.speechlessSection1Image82x.sourceUrl,
                altText: mainData.speechlessSection1Image82x.altText,
                width: mainData.speechlessSection1Image82x.width,
              }
            }
            image91x={
            {
              sourceUrl: mainData.speechlessSection1Image91x.sourceUrl,
              altText: mainData.speechlessSection1Image91x.altText,
              width: mainData.speechlessSection1Image91x.width,
            }
            }
            image92x={
              {
                sourceUrl: mainData.speechlessSection1Image92x.sourceUrl,
                altText: mainData.speechlessSection1Image92x.altText,
                width: mainData.speechlessSection1Image92x.width,
              }
            }
            image101x={
            {
              sourceUrl: mainData.speechlessSection1Image101x.sourceUrl,
              altText: mainData.speechlessSection1Image101x.altText,
              width: mainData.speechlessSection1Image101x.width,
            }
            }
            image102x={
              {
                sourceUrl: mainData.speechlessSection1Image102x.sourceUrl,
                altText: mainData.speechlessSection1Image102x.altText,
                width: mainData.speechlessSection1Image102x.width,
              }
            }
            
        />
        <TestimonialSection
            extraClasses=""
            mainTitle={parse(mainData.speechlessTestimonialSectionTitle)}
            message={parse(mainData.speechlessTestimonialSectionDescription)}
            name={parse(mainData.speechlessTestimonialSectionName)}
            designation={parse(mainData.speechlessTestimonialSectionDesignation)}
            headshot1x={
              {
                sourceUrl: mainData.speechlessTestimonialSectionHeadshot1x.sourceUrl,
                altText: mainData.speechlessTestimonialSectionHeadshot1x.altText,
                width: mainData.speechlessTestimonialSectionHeadshot1x.width,
              }
            }
            headshot2x={
              {
                sourceUrl: mainData.speechlessTestimonialSectionHeadshot2x.sourceUrl,
                altText: mainData.speechlessTestimonialSectionHeadshot2x.altText,
                width: mainData.speechlessTestimonialSectionHeadshot2x.width,
              }
            }
            logo={
              {
                sourceUrl: mainData.speechlessTestimonialSectionLogo.sourceUrl,
                altText: mainData.speechlessTestimonialSectionLogo.altText,
                width: mainData.speechlessTestimonialSectionLogo.width,
              }
            }
        />
      </div>
    </Layout>
  )
}



export const SpeechlessQuery = graphql`
{
    allWpPage(filter: {slug: {eq: "speechless"}}) {
      nodes {
        caseStudySpeechlessPageACF {
          speechlessHeroSectionPretitle
          speechlessHeroSectionTitle
          speechlessHeroSectionImage1x {
            altText
            sourceUrl
            width
          }
          speechlessHeroSectionImage2x {
            altText
            sourceUrl
            width
          }
          speechlessChallengeSectionCompanyInfoList {
            title
            description
          }
          speechlessChallengeSectionChallangesList {
            title
            description
          }
          speechlessSection1ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          speechlessSection1Image11x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image12x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image21x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image22x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Title1
          speechlessSection1Description1
          speechlessSection1IdeationAndSketchesImage1x {
            altText
            sourceUrl
            width
          }
          speechlessSection1IdeationAndSketchesImage2x {
            altText
            sourceUrl
            width
          }
          speechlessSection1ImageList2 {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          speechlessSection1Image31x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image32x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image41x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image42x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image51x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image52x {
            altText
            sourceUrl
            width
          }
        speechlessSection1Title2
        speechlessSection1Description2
        speechlessSection1ImageList3 {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          speechlessSection1Image61x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image62x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image71x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image72x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image81x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image82x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image91x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image92x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image101x {
            altText
            sourceUrl
            width
          }
          speechlessSection1Image102x {
            altText
            sourceUrl
            width
          }
          speechlessTestimonialSectionTitle
          speechlessTestimonialSectionDescription
          speechlessTestimonialSectionHeadshot1x {
            altText
            sourceUrl
            width
          }
          speechlessTestimonialSectionHeadshot2x {
            altText
            sourceUrl
            width
          }
          speechlessTestimonialSectionName
          speechlessTestimonialSectionDesignation
          speechlessTestimonialSectionLogo {
            altText
            sourceUrl
            width
          }
        }
      }
    }
}

`

export default Speechless